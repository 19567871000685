import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Flex, SimpleGrid, Button, useToast } from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import TextField from "components/fields/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "utils/axiosInstance";

interface FormAgamaSettingProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const FormAgamaSetting: React.FC<FormAgamaSettingProps> = ({
  action,
  initialData,
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const toast = useToast();

  const schema = yup.object({}).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const { mutate: createAgama, isLoading: isCreating } = useMutation({
    mutationFn: async ({ newAgama }: { newAgama: any }) => {
      const { data } = await axiosInstance.call({
        customContentType: "multipart/form-data",
        method: "post",
        url: "/general/agama",
        data: newAgama,
      });
      return data;
    },
    onSuccess: () => {
      toast({
        title: "New agama successfully created",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["agamas"] });
      history.push("/admin/settings/data/agama");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  const { mutate: editAgama, isLoading: isEditing } = useMutation({
    mutationFn: async ({ newAgama }: { newAgama: any }) => {
      const { data } = await axiosInstance.call({
        customContentType: "multipart/form-data",
        method: "post",
        url: "/general/agama/update",
        data: newAgama,
      });
      return data;
    },
    onSuccess: () => {
      toast({
        title: "Agama successfully edited",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["agamas"] });
      history.push("/admin/settings/data/agama");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  const onSubmit = handleSubmit((data) => {
    if (action === "create") {
      createAgama({ newAgama: data });
    } else {
      editAgama({ newAgama: data });
    }
  });

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            <Section title="Maklumat Agama">
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                <TextField
                  id="name"
                  label="Agama"
                  // isInvalid={!!errors.no_fail_ptg}
                  // errorMessage={errors.no_fail_ptg?.message}
                  readOnly={action === "show"}
                  {...register("name")}
                />
              </SimpleGrid>

              <Flex gap="20px" justifyContent={"flex-end"}>
                <Button
                  variant="outline"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  colorScheme="gray"
                  type={action === "edit" ? "button" : "reset"}
                  onClick={() => {
                    if (action !== "create") {
                      history.push("/admin/settings/data");
                    }
                  }}
                >
                  {action !== "show" ? "Batal" : "Kembali"}
                </Button>

                {action !== "show" && (
                  <Button
                    variant="brand"
                    fontSize={"sm"}
                    fontWeight={"500"}
                    minW={"183px"}
                    type="submit"
                  >
                    {action === "edit"
                      ? "Kemaskini Agama"
                      : "Daftar Agama Baru"}
                  </Button>
                )}
              </Flex>
            </Section>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormAgamaSetting;
