import React from "react";
import { useHistory, NavLink, useLocation } from "react-router-dom";
import { Box, Text, Link, List, Icon, useToast } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete } from "react-icons/md";

import Resource from "components/resource/Resource";
import FullScreenLoader from "components/loader/FullScreenLoader";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axiosInstance from "utils/axiosInstance";
import ResourceTwo from "components/resourceTwo/ResourceTwo";

const columnHelper = createColumnHelper<any>();

const NoFailPtgSetting = () => {
  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = !searchParams.get("page")
    ? 1
    : Number(searchParams.get("page"));
  const currentLimit = !searchParams.get("itemsPerPage")
    ? 50
    : Number(searchParams.get("itemsPerPage"));

  const {
    isLoading,
    data: jenisAktivitis,
    error,
  } = useQuery({
    queryKey: ["jenis-aktivitis", currentPage, currentLimit],
    queryFn: async () => {
      const { data } = await axiosInstance.call({
        method: "GET",
        url: "/general/jenis-aktiviti",
        params: {
          page: currentPage,
          itemsPerPage: currentLimit,
        },
      });

      return data;
    },
  });

  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate: deleteJenisAktiviti, isLoading: isEditing } = useMutation({
    mutationFn: async ({ newJenisAktiviti }: { newJenisAktiviti: any }) => {
      const { data } = await axiosInstance.call({
        method: "post",
        url: "/general/jenis-aktiviti/delete",
        data: newJenisAktiviti,
      });
      return data;
    },
    onSuccess: () => {
      toast({
        title: "JenisAktiviti successfully deleted",
        status: "success",
        isClosable: true,
      });
      queryClient.invalidateQueries({ queryKey: ["jenis-aktivitis"] });
      history.push("/admin/settings/data/jenis-aktiviti");
    },
    onError: (err: any) => {
      console.error("ERROR", err);
      toast({
        title: err?.message || "Unexpected error",
        status: "error",
        isClosable: true,
      });
    },
  });

  const columns = [
    columnHelper.accessor("value", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("label", {
      header: () => <Text variant="tableHeading">Jenis</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("updated_at", {
      header: () => <Text variant="tableHeading">Tarikh Kemaskini</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {new Date(info.getValue()).toLocaleDateString()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List spacing={2}>
          <NavLink
            to={`/admin/settings/data/jenis-aktiviti/kemaskini/${info.row.original.value}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <Link
            as={"button"}
            onClick={() => {
              deleteJenisAktiviti({
                newJenisAktiviti: { id: info.row.original.value },
              });
            }}
            disabled={false}
          >
            <Icon w="24px" h="24px" me="5px" color={"red.500"} as={MdDelete} />
          </Link>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={jenisAktivitis}
        title={"Senarai Jenis Aktiviti"}
        createTitle="Tambah"
        onCreate={() =>
          history.push("/admin/settings/data/jenis-aktiviti/baru")
        }
        allowPagination={false}
      />
    </Box>
  );
};

export default NoFailPtgSetting;
