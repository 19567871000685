import { Icon } from "@chakra-ui/react";
import { MdBarChart } from "react-icons/md";

import MainDashboard from "views/admin/dashboard";
import Aktiviti from "views/admin/report/aktiviti";
import Pendapatan from "views/admin/report/pendapatan";

const reportRoutes = [
  {
    name: "Laporan",
    layout: "/admin",
    path: "/reports",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: "Laporan Pendapatan",
        layout: "/admin",
        path: "/reports/pendapatan",
        component: Pendapatan,
      },
      {
        name: "Laporan Hasil Jualan",
        layout: "/admin",
        path: "/reports/aktiviti",
        component: Aktiviti,
      },
      {
        name: "Laporan Analisis",
        layout: "/admin",
        path: "/reports/aktiviti",
        component: Aktiviti,
      },
      {
        name: "Laporan Rumusan",
        layout: "/admin",
        path: "/reports/aktiviti",
        component: Aktiviti,
      },
      {
        name: "Laporan Perbandingan Pendapatan",
        layout: "/admin",
        path: "/reports/aktiviti",
        component: Aktiviti,
      },
      {
        name: "Laporan Aktiviti",
        layout: "/admin",
        path: "/reports/aktiviti",
        component: Aktiviti,
      },
    ],
  },
];

export default reportRoutes;
