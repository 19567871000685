import React from "react";
import FormSijilTertinggiSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import axiosInstance from "utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export default function EditSijilTertinggiSetting() {
  const { sijilTertinggiId } = useParams<{ sijilTertinggiId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: sijilTertinggi,
    error,
  } = useQuery({
    queryKey: ["sijilTertinggi", sijilTertinggiId],
    queryFn: async () => {
      const {data} = await axiosInstance.call({
        method: "GET",
        url: `/general/sijil-tertinggi/show/${sijilTertinggiId}`,
      });

      return data;
    },
    enabled: !!sijilTertinggiId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormSijilTertinggiSetting action="edit" initialData={sijilTertinggi?.data} />;
}
