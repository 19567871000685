import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/react";
import SettingList from "./components/SettingList";

export default function DataSetting() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        <SettingList
          title="Tetapan Profil"
          settings={[
            {
              text: "Bangsa",
              link: "/admin/settings/data/bangsa",
            },
            {
              text: "Agama",
              link: "/admin/settings/data/agama",
            },
            {
              text: "Etnik",
              link: "/admin/settings/data/etnik",
            },
            {
              text: "Status Perkahwinan",
              link: "/admin/settings/data/status-perkahwinan",
            },
            {
              text: "Tahap Pendidikan",
              link: "/admin/settings/data/tahap-pendidikan",
            },
            {
              text: "Sijil Tertinggi",
              link: "/admin/settings/data/sijil-tertinggi",
            },
          ]}
        />

        <SettingList
          title="Tetapan Syarikat"
          settings={[
            {
              text: "Jenis Milikan",
              link: "/admin/settings/data/jenis-milikan",
            },
            {
              text: "Jenis Perniagaan",
              link: "/admin/settings/data/jenis-perniagaan",
            },
          ]}
        />
        
        <SettingList
          title="Tetapan Umum"
          settings={[
            {
              text: "Aktiviti",
              link: "/admin/settings/data/jenis-aktiviti",
            },
            {
              text: "Aliran Tunai",
              link: "/admin/settings/data/jenis-aliran",
            },
          ]}
        />
      </SimpleGrid>
    </Box>
  );
}
