import React from "react";
import FormBangsaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import axiosInstance from "utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export default function EditBangsaSetting() {
  const { jenisPerniagaanId } = useParams<{ jenisPerniagaanId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: jenisPerniagaan,
    error,
  } = useQuery({
    queryKey: ["jenisPerniagaan", jenisPerniagaanId],
    queryFn: async () => {
      const {data} = await axiosInstance.call({
        method: "GET",
        url: `/general/jenis-perniagaan/show/${jenisPerniagaanId}`,
      });

      return data;
    },
    enabled: !!jenisPerniagaanId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormBangsaSetting action="edit" initialData={jenisPerniagaan?.data} />;
}
