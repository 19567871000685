import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
  Button,
  useToast,
  Text,
  List,
  Icon,
} from "@chakra-ui/react";

import Card from "components/card/Card";
import Section from "components/section/Section";
import SelectField from "components/fields/SelectField";
import TextField from "components/fields/TextField";
import FullScreenLoader from "components/loader/FullScreenLoader";

import useOptions from "hooks/setting/general/useOptions";

import { IUserFormValues } from "entities/setting/user";
import { useCreateUser } from "hooks/setting/user/useCreateUser";
import { useEditUser } from "hooks/setting/user/useEditUser";
import axiosInstance from "utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { set } from "lodash";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import { createColumnHelper } from "@tanstack/react-table";
import { MdAdd, MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

interface FormUserProps {
  action: "create" | "edit" | "show";
  initialData?: any;
}

const columnHelper = createColumnHelper<any>();

const FormUser: React.FC<FormUserProps> = ({ action, initialData }) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [showPassword, setShowPassword] = useState(false);
  const { createUser, isCreating } = useCreateUser();
  const { editUser, isEditing } = useEditUser();
  const [userExists, setUserExists] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [espekUser, setEspekUser] = useState<any>(null);
  const [searchValues, setSearchValues] = useState({
    search: "",
  });

  const toast = useToast();

  const { pusatTanggungjawabOps, negeriOps, daerahOps, roleOps, bahagianOps } =
    useOptions();

  const { search } = useParams<{ search: string }>();

  const schema = yup
    .object({
      name: yup.string().required(),
      email: yup.string().email().required(),
      pusat_tanggungjawap_id: yup.number().notRequired(),
      nickname: yup.string().notRequired(),
      nric: yup.string().required(),
      phone_no: yup.string().notRequired(),
      position: yup.string().notRequired(),
      is_active: yup.number().required(),
      role_id: yup.number().required(),
      department_id: yup.string().notRequired(),
      password:
        action === "create"
          ? yup.string().required()
          : yup.string().notRequired(),
    })
    .required();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IUserFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: initialData,
  });

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const onSubmit = handleSubmit((data) => {
    if (action === "create" && !isCreating) {
      createUser({
        newUser: { ...data, espek_id: selectedUser.nopekerja },
        id: null,
      });
    } else if (action === "edit" && !isEditing) {
      editUser({
        newUser: data,
        id: `${data.id}`,
      });
    }
  });

  const searchC = async () => {
    searchParams.set("search", searchValues.search);

    if (searchValues.search === "") {
      toast({
        title: "Sila isi nama pengguna",
        status: "error",
        isClosable: true,
      });
      setUserExists(false);
      setEspekUser(null);
      return;
    }

    const { data } = await axiosInstance.call({
      method: "GET",
      url: `/espek-user`,
      params: {
        search: searchValues.search,
      },
    });

    if (data.data.items.length > 0) {
      setEspekUser(data);
    } else {
      toast({
        title: "Pengguna tidak wujud dalam eSPEK",
        status: "error",
        isClosable: true,
      });
      setEspekUser(null);
      return;
    }

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      search: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    setUserExists(false);
    setEspekUser(null);

    history.push({ search: searchParams.toString() });
  };

  const columns = [
    columnHelper.accessor("nopekerja", {
      header: () => <Text variant="tableHeading">ID eSPEK</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("Jawatan", {
      header: () => <Text variant="tableHeading">Jawatan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      header: () => <Text variant="tableHeading">Email</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nokp", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="max-content"
            px="18px"
            onClick={() => {
              setSelectedUser(info.row.original);
              setValue("name", info.row.original.nama);
              setValue("nickname", info.row.original.nama);
              setValue("email", info.row.original.email);
              setValue("nric", info.row.original.nokp);
              setValue("position", info.row.original.Jawatan);
              setValue("phone_no", info.row.original.notel);
              setEspekUser(null);
              setUserExists(true);
            }}
            type="button"
          >
            Tambah Pengguna
          </Button>
        </List>
      ),
    }),
  ];

  if (false) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mt="1rem" mb="1.5rem" flexDirection={"column"}>
          <form onSubmit={onSubmit} autoComplete="off">
            {action === "create" && (
              <Section title="Carian Pengguna">
                <SimpleGrid
                  columns={{ base: 2 }}
                  gap={{ sm: "6px", md: "18px" }}
                  mb={10}
                >
                  <FormControl>
                    <FormLabel variant="main" mb="8px">
                      Nama Pengguna
                    </FormLabel>

                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      fontWeight="500"
                      size="md"
                      value={searchValues.search}
                      onChange={(e: any) =>
                        setSearchValues({
                          search: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                  <Flex gap={2} alignItems="center" alignSelf="flex-end">
                    <Button
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="max-content"
                      px="18px"
                      onClick={searchC}
                      type="button"
                    >
                      Carian eSPEK
                    </Button>

                    <Button
                      fontSize="sm"
                      variant="outline"
                      fontWeight="500"
                      w="max-content"
                      type="button"
                      onClick={reset}
                    >
                      Set Semula
                    </Button>
                  </Flex>
                </SimpleGrid>
              </Section>
            )}

            {espekUser && (
              <>
                <ResourceTwo
                  columns={columns}
                  data={espekUser}
                  title={"Senarai Pengguna eSPEK"}
                  allowPagination
                />
              </>
            )}

            <Section title={userExists ? "Maklumat Pengguna" : ""}>
              <SimpleGrid
                columns={{ base: 2 }}
                gap={{ sm: "6px", md: "18px" }}
                mb={10}
              >
                {userExists && action === "create" && (
                  <>
                    <TextField
                      id="name"
                      label="Nama Pengguna"
                      placeholder="Ramli Saad"
                      isInvalid={!!errors.name}
                      errorMessage={errors.name?.message}
                      autoComplete="nope"
                      {...register("name")}
                    />

                    <TextField
                      id="nickname"
                      label="Nama Panggilan"
                      isInvalid={!!errors.nickname}
                      errorMessage={errors.nickname?.message}
                      {...register("nickname")}
                    />

                    <TextField
                      id="email"
                      label="Emel"
                      isInvalid={!!errors.email}
                      errorMessage={errors.email?.message}
                      autoComplete="nope"
                      {...register("email")}
                    />

                    <TextField
                      id="nric"
                      label="No. Kad Pengenalan"
                      isInvalid={!!errors.nric}
                      errorMessage={errors.nric?.message}
                      {...register("nric")}
                    />

                    {action === "create" && (
                      <TextField
                        id="phone_no"
                        label="No. Telefon"
                        isInvalid={!!errors.phone_no}
                        errorMessage={errors.phone_no?.message}
                        {...register("phone_no")}
                      />
                    )}

                    <TextField
                      id="position"
                      label="Jawatan"
                      isInvalid={!!errors.position}
                      errorMessage={errors.position?.message}
                      {...register("position")}
                    />

                    <SelectField
                      label={"Bahagian"}
                      options={bahagianOps}
                      isInvalid={!!errors.department_id}
                      errorMessage={errors.department_id?.message}
                      {...register("department_id")}
                    />

                    {action === "create" && (
                      <SelectField
                        label={"Pusat Tanggungjawab"}
                        options={pusatTanggungjawabOps}
                        isInvalid={!!errors.pusat_tanggungjawap_id}
                        errorMessage={errors.pusat_tanggungjawap_id?.message}
                        {...register("pusat_tanggungjawap_id")}
                      />
                    )}

                    <SelectField
                      label={"Status"}
                      options={[
                        {
                          value: "0",
                          label: "Aktif",
                        },
                        {
                          value: "1",
                          label: "Tidak Aktif",
                        },
                      ]}
                      isInvalid={!!errors.is_active}
                      errorMessage={errors.is_active?.message}
                      {...register("is_active")}
                    />

                    <SelectField
                      label={"Peranan"}
                      options={roleOps.filter(
                        (role: any) =>
                          role.label !== "Anchor" &&
                          role.label !== "Integrator" &&
                          role.label !== "Usahawan"
                      )}
                      isInvalid={!!errors.role_id}
                      errorMessage={errors.role_id?.message}
                      {...register("role_id")}
                    />
                  </>
                )}

                {action !== "create" && (
                  <>
                    <TextField
                      id="name"
                      label="Nama Pengguna"
                      placeholder="Ramli Saad"
                      isInvalid={!!errors.name}
                      errorMessage={errors.name?.message}
                      readOnly={true}
                      autoComplete="nope"
                      {...register("name")}
                    />

                    <TextField
                      id="nickname"
                      label="Nama Panggilan"
                      isInvalid={!!errors.nickname}
                      errorMessage={errors.nickname?.message}
                      readOnly={action === "show"}
                      {...register("nickname")}
                    />

                    <TextField
                      id="email"
                      label="Emel"
                      isInvalid={!!errors.email}
                      errorMessage={errors.email?.message}
                      readOnly
                      autoComplete="nope"
                      {...register("email")}
                    />

                    <TextField
                      id="nric"
                      label="No. Kad Pengenalan"
                      isInvalid={!!errors.nric}
                      errorMessage={errors.nric?.message}
                      readOnly
                      {...register("nric")}
                    />

                    <TextField
                      id="position"
                      label="Jawatan"
                      isInvalid={!!errors.position}
                      errorMessage={errors.position?.message}
                      readOnly={action === "show"}
                      {...register("position")}
                    />

                    <SelectField
                      label={"Bahagian"}
                      options={bahagianOps}
                      isInvalid={!!errors.department_id}
                      errorMessage={errors.department_id?.message}
                      readOnly={action === "show"}
                      {...register("department_id")}
                    />

                    <SelectField
                      label={"Status"}
                      options={[
                        {
                          value: "0",
                          label: "Aktif",
                        },
                        {
                          value: "1",
                          label: "Tidak Aktif",
                        },
                      ]}
                      isInvalid={!!errors.is_active}
                      errorMessage={errors.is_active?.message}
                      readOnly={action === "show"}
                      {...register("is_active")}
                    />

                    <>
                      <SelectField
                        label={"Status Espek"}
                        options={[
                          {
                            value: "0",
                            label: "Aktif",
                          },
                          {
                            value: "1",
                            label: "Tidak Aktif",
                          },
                        ]}
                        value={initialData?.profile?.status_espek}
                        readOnly
                      />

                      <SelectField
                        label={"Negeri Espek"}
                        options={negeriOps}
                        value={initialData?.profile?.id_negeri_espek}
                        readOnly
                      />

                      <SelectField
                        label={"Pusat Tanggungjawapan Espek"}
                        options={pusatTanggungjawabOps}
                        value={initialData?.profile?.id_pt_espek}
                        readOnly
                      />

                      <SelectField
                        label={"Daerah Espek"}
                        options={daerahOps}
                        value={initialData?.profile?.id_daerah_espek}
                        readOnly
                      />
                    </>

                    <SelectField
                      label={"Peranan"}
                      options={roleOps.filter(
                        (role: any) =>
                          role.label !== "Anchor" &&
                          role.label !== "Integrator" &&
                          role.label !== "Usahawan"
                      )}
                      isInvalid={!!errors.role_id}
                      errorMessage={errors.role_id?.message}
                      readOnly={action === "show"}
                      {...register("role_id")}
                    />
                  </>
                )}
              </SimpleGrid>
            </Section>

            {action === "create" && userExists && (
              <Section title="Maklumat Kata Laluan">
                <SimpleGrid
                  columns={{ base: 2 }}
                  gap={{ sm: "6px", md: "18px" }}
                  mb={10}
                >
                  <TextField
                    id="password"
                    label="Kata Laluan"
                    placeholder="Min. 5 characters"
                    type="password"
                    showPassword={showPassword}
                    toggleShowPassword={toggleShowPassword}
                    isInvalid={!!errors.password}
                    errorMessage={errors.password?.message}
                    autoComplete="new-password"
                    {...register("password")}
                  />

                  <FormControl>
                    <FormLabel
                      variant="main"
                      mb="8px"
                      htmlFor={"password_confirm"}
                    >
                      Pengesahan Kata Laluan
                    </FormLabel>

                    <InputGroup size="md">
                      <Input
                        id={"password_confirm"}
                        variant={"auth"}
                        fontSize={"sm"}
                        ms={{ base: "0px", md: "0px" }}
                        type={"text"}
                        placeholder={"Sahkan Kata Laluan"}
                        fontWeight={"500"}
                        size={"md"}
                      />
                    </InputGroup>

                    {false && (
                      <FormErrorMessage>
                        Kata laluan tidak sama
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </SimpleGrid>
              </Section>
            )}

            <Flex gap="20px" justifyContent={"flex-end"}>
              <Button
                variant="outline"
                fontSize={"sm"}
                fontWeight={"500"}
                minW={"183px"}
                colorScheme="gray"
                type={"button"}
                onClick={() => {
                  history.push("/admin/users");
                }}
              >
                Kembali
              </Button>

              {userExists && action === "create" && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  Daftar Pengguna Baru
                </Button>
              )}

              {action === 'edit' && userExists === false && (
                <Button
                  variant="brand"
                  fontSize={"sm"}
                  fontWeight={"500"}
                  minW={"183px"}
                  type="submit"
                >
                  {action === "edit"
                    ? "Kemaskini Pengguna"
                    : "Daftar Pengguna Baru"}
                </Button>
              )}
            </Flex>
          </form>
        </Flex>
      </Card>
    </Box>
  );
};

export default FormUser;
