import { useQuery } from "@tanstack/react-query";
import { getOptions } from "services/setting/optionService";

export default function useOptions() {
  const { data: options } = useQuery({
    queryKey: ["options"],
    queryFn: getOptions,
  });

  const extractOptions = (optionKey: string) =>
    options?.data?.[optionKey] || [];
  const negeriOps = extractOptions("negeri");
  const daerahOps = extractOptions("daerah");
  const pusatTanggungjawabOps = extractOptions("pusatTanggungjawab");
  const tahapKebenaranOps = extractOptions("tahapKebenaran");
  const roleOps = extractOptions("roles");
  const tapakProjekOps = extractOptions("tapakProjek");
  const namaHasilOps = extractOptions("namaHasil");
  const jenisAktivitiOps = extractOptions("jenisAktiviti");
  const jantinaOps = extractOptions("jantina");
  const bangsaOps = extractOptions("bangsa");
  const agamaOps = extractOptions("agama");
  const etnikOps = extractOptions("etnik");
  const bandarOps = extractOptions('bandar');
  const dunOps = extractOptions('dun');
  const kampungOps = extractOptions('kampung');
  const mukimOps = extractOptions('mukim');
  const parlimenOps = extractOptions('parlimen');
  const seksyenOps = extractOptions('seksyen');
  const stesenOps = extractOptions('stesen');
  const tahapPendidikanOps = extractOptions('tahapPendidikan');
  const statusKahwinOps = extractOptions('statusKahwin');
  const sijilTertinggiOps = extractOptions('sijilTertinggi');
  const bahagianOps = extractOptions('bahagian');

  // CONSTANT
  const statusPaparanOps = [
    { value: "1", label: "Diterbitkan" },
    { value: "0", label: "Draf" },
  ];

  const statusPengesahanOps = [
    { value: "0", label: "Menunggu Pengesahan" },
    { value: "1", label: "Disahkan" },
  ];

  const statusPaparanKatalogOps = [
    { value: "1", label: "Papar" },
    { value: "0", label: "Tidak Papar" },
  ];

  const jenisLatihanOps = [
    { value: "1", label: "E-Latihan" },
    { value: "0", label: "Bukan E-Latihan" },
  ];

  const statusLawatanOps = [
    { value: "0", label: "Pengesahan Usahawan" },
    { value: "1", label: "Disahkan" },
    { value: "2", label: "Pengesahan Pegawai" },
  ];

  const statusUsahawanOps = [
    { value: "0", label: "Tidak Aktif" },
    { value: "1", label: "Aktif" },
    { value: "2", label: "Dihalang" },
  ];

  const statusBumiputeraOps = [
    { value: "0", label: "Ya" },
    { value: "1", label: "Tidak" },
  ];

  const capaiSasaranOps = [
    { value: "0", label: "Tidak" },
    { value: "1", label: "Ya" },
  ];

  const statusProjekOps = [
    { value: "0", label: "Tidak Aktif" },
    { value: "1", label: "Aktif" },
    { value: "2", label: "Gagal" },
  ];

  const statusSyarikatOps = [
    { value: "0", label: "Tidak Aktif" },
    { value: "1", label: "Aktif" },
  ];

  const jenisDokumenOps = [
    { value: "invois", label: "Invois" },
    { value: "nota hantaran", label: "Nota Hantaran" },
    { value: "sebut harga", label: "Sebut Harga" },
  ];

  const bulanOps = [
    { value: "1", label: "Januari" },
    { value: "2", label: "Februari" },
    { value: "3", label: "Mac" },
    { value: "4", label: "April" },
    { value: "5", label: "Mei" },
    { value: "6", label: "Jun" },
    { value: "7", label: "Julai" },
    { value: "8", label: "Ogos" },
    { value: "9", label: "September" },
    { value: "10", label: "Oktober" },
    { value: "11", label: "November" },
    { value: "12", label: "Disember" },
  ];

  const tahunOps = [
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
    { value: "2026", label: "2026" },
    { value: "2027", label: "2027" },
    { value: "2028", label: "2028" },
    { value: "2029", label: "2029" },
    { value: "2030", label: "2030" },
  ];

  // OUTPUT
  return {
    statusPaparanOps,
    statusPaparanKatalogOps,
    statusPengesahanOps,
    negeriOps,
    daerahOps,
    pusatTanggungjawabOps,
    tahapKebenaranOps,
    roleOps,
    tapakProjekOps,
    jenisAktivitiOps,
    namaHasilOps,
    jantinaOps,
    agamaOps,
    bangsaOps,
    etnikOps,
    bandarOps,
    dunOps,
    seksyenOps,
    stesenOps,
    mukimOps,
    kampungOps,
    parlimenOps,
    jenisLatihanOps,
    statusLawatanOps,
    statusUsahawanOps,
    statusBumiputeraOps,
    capaiSasaranOps,
    statusProjekOps,
    tahapPendidikanOps,
    statusKahwinOps,
    sijilTertinggiOps,
    statusSyarikatOps,
    jenisDokumenOps,
    bulanOps,
    tahunOps,
    bahagianOps
  };
}
