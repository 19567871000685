import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  FormControl,
  SimpleGrid,
} from "@chakra-ui/react";

import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";
import { useNegeriSettings } from "hooks/setting/general/negeri/useNegeris";
import { usePTSettings } from "hooks/setting/general/pt/usePTs";
import { useDaerahSettings } from "hooks/setting/general/daerah/useDaerahs";

export default function Sorting() {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const { negeriOps, tahapKebenaranOps, roleOps } = useOptions();

  const [pusatTanggungjawabOps, setPtOps] = useState<any>([]);
  const [daerahOps, setDaerahOps] = useState<any>([]);

  const { negeris, isLoading: isNegeriLoading } = useNegeriSettings({
    paginate: false,
  });
  const { pts, isLoading: isPtLoading } = usePTSettings({
    paginate: false,
  });
  const { daerahs, isLoading: isDaerahLoading } = useDaerahSettings({
    paginate: false,
  });

  const [searchValues, setSearchValues] = useState({
    role_id: searchParams.get("role_id") || "",
    tahap_kebenaran: searchParams.get("tahap_kebenaran") || "",
    negeri_kebenaran: searchParams.get("negeri_kebenaran") || "",
    pt_kebenaran: searchParams.get("pt_kebenaran") || "",
    daerah_kebenaran: searchParams.get("daerah_kebenaran") || "",
  });

  const handleOnChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    fieldName: string
  ) => {
    const selectedValue = e.target.value;
    setSearchValues((prevState) => {
      return {
        ...prevState,
        [fieldName]: selectedValue,
      };
    });
  };

  const removeDefaultParams = () => {
    searchParams.delete("page");
    searchParams.delete("limit");
  };

  const search = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value.toString());
      }
    });

    history.push({ search: searchParams.toString() });
  };

  const reset = () => {
    removeDefaultParams();

    Object.entries(searchValues).forEach(([key, value]) => {
      searchParams.delete(key);
    });

    setSearchValues({
      role_id: "",
      tahap_kebenaran: "",
      negeri_kebenaran: "",
      pt_kebenaran: "",
      daerah_kebenaran: "",
    });
    document
      .querySelectorAll("select")
      .forEach((select) => (select.value = ""));

    history.push({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (searchValues.negeri_kebenaran && pts?.data.items) {
      setSearchValues((prevState) => ({
        ...prevState,
        pt_kebenaran: "",
      }));

      const ptSelect = document.querySelector("select[id='pt_id']") as HTMLSelectElement;

      if (ptSelect) {
        ptSelect.value = "";
      }

      const pt = pts?.data.items
        .filter((pt: any) => pt.negeri_id == searchValues.negeri_kebenaran)
        .map((pt: any) => {
          return {
            label: pt.keterangan,
            value: pt.id,
          };
        });

      setPtOps(pt);
    }
  }, [searchValues.negeri_kebenaran, pts]);

  useEffect(() => {
    if (searchValues.pt_kebenaran && daerahs?.data.items) {
      setSearchValues((prevState) => ({
        ...prevState,
        daerah_id: "",
      }));
      
      const daerahSelect = document.querySelector("select[id='daerah_id']") as HTMLSelectElement;

      if (daerahSelect) {
        daerahSelect.value = "";
      }

      const daerah = daerahs?.data.items
        .filter(
          (daerah: any) => daerah.pusat_tanggungjawap_id == searchValues.pt_kebenaran
        )
        .map((daerah: any) => {
          return {
            label: daerah.daerah,
            value: daerah.id,
          };
        });

      setDaerahOps(daerah);
    }
  }, [searchValues.pt_kebenaran, daerahs]);

  return (
    <FormControl>
      <SimpleGrid
        columns={{ base: 3 }}
        gap={{ sm: "6px", md: "18px" }}
        mb="18px"
      >
        <FormControl>
          <SelectField
            label={"Nama Peranan"}
            value={searchValues.role_id}
            options={roleOps}
            onChange={(e: any) => handleOnChange(e, "role_id")}
            
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Tahap Kebenaran"}
            value={searchValues.tahap_kebenaran}
            options={tahapKebenaranOps}
            onChange={(e: any) => handleOnChange(e, "tahap_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Negeri"}
            value={searchValues.negeri_kebenaran}
            options={negeriOps}
            onChange={(e: any) => handleOnChange(e, "negeri_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Pusat Tanggungjawab"}
            value={searchValues.pt_kebenaran}
            options={pusatTanggungjawabOps}
            onChange={(e: any) => handleOnChange(e, "pt_kebenaran")}
          />
        </FormControl>

        <FormControl>
          <SelectField
            label={"Daerah"}
            value={searchValues.daerah_kebenaran}
            options={daerahOps}
            onChange={(e: any) => handleOnChange(e, "daerah_kebenaran")}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        fontSize="sm"
        variant="brand"
        fontWeight="500"
        w="max-content"
        px="18px"
        onClick={search}
      >
        Cari
      </Button>

      <Button
        fontSize="sm"
        variant="danger"
        fontWeight="500"
        w="max-content"
        px="18px"
        ml={"10px"}
        onClick={reset}
      >
        Set Semula
      </Button>
    </FormControl>
  );
}
