import React, { useMemo } from "react";
import { Box, Text, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";

import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

import { useUsers } from "hooks/setting/user/useUsers";

import { IUserTable } from "entities/admin/pengguna/index";
import { useCurrentUser } from "hooks/setting/user/useCurrentUser";

const columnHelper = createColumnHelper<IUserTable>();

export default function User() {
  const { isLoading, error, users } = useUsers();
  const { currentUser } = useCurrentUser();
  const history = useHistory();

  const isUpdateAllowed = useMemo(() => {
    if (
      currentUser?.data?.roles &&
      Array.isArray(currentUser.data.roles) &&
      currentUser.data.roles.length > 0
    ) {
      const permissions = currentUser.data.roles[0].permissions.map(
        (p: any) => p.name
      );
      return permissions.includes("store user");
    }

    return false;
  }, [currentUser]);

  const columns = [
    columnHelper.accessor("user_id", {
      header: () => <Text variant="tableHeading">ID Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("espek_id", {
      header: () => <Text variant="tableHeading">ID ESPEK</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_pengguna", {
      header: () => <Text variant="tableHeading">Nama Pengguna</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => <Text variant="tableHeading">Emel Pengguna</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("roles", {
      header: () => <Text variant="tableHeading">Nama Peranan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue().length > 0 ? info.getValue()[0] : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("tahap_kebenarans", {
      header: () => <Text variant="tableHeading">Tahap Kebenaran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue().length > 0 ? info.getValue()[0] : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("negeris", {
      header: () => <Text variant="tableHeading">Negeri Kebenaran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary" whiteSpace="normal">
          {info.getValue()[0] !== "" ? info.getValue().join("\n") : "Semua"}
        </Text>
      ),
    }),
    columnHelper.accessor("pusat_tanggungjawaps", {
      header: () => <Text variant="tableHeading">PT Kebenaran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()[0] !== "" ? info.getValue().join("\n") : "Semua"}
        </Text>
      ),
    }),
    columnHelper.accessor("daerahs", {
      header: () => <Text variant="tableHeading">Daerah Kebenaran</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()[0] !== "" ? info.getValue().join("\n") : "Semua"}
        </Text>
      ),
    }),

    columnHelper.accessor("is_active_label", {
      header: () => <Text variant="tableHeading">Status</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.user_id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink
            to={`/admin/settings/users/kemaskini/${info.row.original.user_id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"blue.500"}
              as={MdEditNote}
            />
          </NavLink>

          <NavLink
            to={`/admin/settings/users/show/${info.row.original.user_id}`}
          >
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={users}
        title={"Senarai Pengguna"}
        createTitle={isUpdateAllowed ? "Tambah Pengguna" : null}
        onCreate={
          isUpdateAllowed
            ? () => history.push("/admin/settings/users/baru")
            : null
        }
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
