import React from "react";
import FormJenisMilikanSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import axiosInstance from "utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export default function EditJenisMilikanSetting() {
  const { jenisMilikanId } = useParams<{ jenisMilikanId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: jenisMilikan,
    error,
  } = useQuery({
    queryKey: ["jenisMilikan", jenisMilikanId],
    queryFn: async () => {
      const {data} = await axiosInstance.call({
        method: "GET",
        url: `/general/jenis-milikan-syarikat/show/${jenisMilikanId}`,
      });

      return data;
    },
    enabled: !!jenisMilikanId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return <FormJenisMilikanSetting action="edit" initialData={jenisMilikan?.data} />;
}
