import React, { useState } from "react";
import { Box, Text, Link, List, Icon } from "@chakra-ui/react";
import { useHistory, NavLink } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { MdEditNote, MdDelete, MdOutlineRemoveRedEye } from "react-icons/md";

import { useProjekBpks } from "hooks/admin/insentif/bpk/useProjekBpks";
import FullScreenLoader from "components/loader/FullScreenLoader";
import ResourceTwo from "components/resourceTwo/ResourceTwo";
import Sorting from "./components/Sorting";

const columnHelper = createColumnHelper<any>();

export default function Insentif() {
  const { isLoading, error, projekBpks } = useProjekBpks();

  const history = useHistory();

  const columns = [
    columnHelper.accessor("id", {
      header: () => <Text variant="tableHeading">ID</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama", {
      header: () => <Text variant="tableHeading">Nama</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_kad_pengenalan", {
      header: () => <Text variant="tableHeading">No. Kad Pengenalan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("emel", {
      id: "emel",
      header: () => <Text variant="tableHeading">Emel</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("no_telefon", {
      header: () => <Text variant="tableHeading">No. Telefon</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_bantuan", {
      header: () => <Text variant="tableHeading">Jenis Bantuan</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kategori_projek", {
      header: () => <Text variant="tableHeading">Kategori Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("sub_kategori", {
      header: () => <Text variant="tableHeading">Sub Kategori</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("nama_projek", {
      header: () => <Text variant="tableHeading">Nama Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("teknologi_projek", {
      header: () => <Text variant="tableHeading">Teknologi Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("jenis_projek", {
      header: () => <Text variant="tableHeading">Jenis Projek</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kategori_pelaksana", {
      header: () => <Text variant="tableHeading">Kategori Pelaksana</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kaedah_pelaksana", {
      header: () => <Text variant="tableHeading">Kaedah Pelaksana</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kumpulan_pelaksana", {
      header: () => <Text variant="tableHeading">Kumpulan Pelaksana</Text>,
      cell: (info: any) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("negeri_kebenaran", {
      header: () => <Text variant="tableHeading">Negeri</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("pt_kebenaran", {
      header: () => <Text variant="tableHeading">Pusat Tanggungjawab</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("daerah_kebenaran", {
      header: () => <Text variant="tableHeading">Daerah</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("kelulusan_projek_bpks", {
      header: () => <Text variant="tableHeading">Tahun Bantuan</Text>,
      cell: (info) => (
        <Text fontSize="sm" variant="secondary">
          {info.getValue()
            .map((item: any) => item.tahun_bantuan)
            .sort((a: any, b: any) => a - b)
            .join(", ")}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row.id, {
      id: "action",
      header: () => <Text variant="tableHeading">Tindakan</Text>,
      cell: (info) => (
        <List minW="max-content" spacing={2}>
          <NavLink to={`/admin/insentif/bpk/show/${info.row.original.id}`}>
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={"green.500"}
              as={MdOutlineRemoveRedEye}
            />
          </NavLink>
        </List>
      ),
    }),
  ];

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ResourceTwo
        columns={columns}
        data={projekBpks}
        title={"Senarai Insentif BPK"}
        allowPagination
        resourceSortingChildren={<Sorting />}
      />
    </Box>
  );
}
