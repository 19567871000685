import React from "react";
import FormTahapPendidikanSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import axiosInstance from "utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export default function EditTahapPendidikanSetting() {
  const { tahapPendidikanId } = useParams<{ tahapPendidikanId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: tahapPendidikan,
    error,
  } = useQuery({
    queryKey: ["tahapPendidikan", tahapPendidikanId],
    queryFn: async () => {
      const {data} = await axiosInstance.call({
        method: "GET",
        url: `/general/tahap-pendidikan/show/${tahapPendidikanId}`,
      });

      return data;
    },
    enabled: !!tahapPendidikanId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }
  return <FormTahapPendidikanSetting action="edit" initialData={tahapPendidikan?.data} />;
}
