import React from "react";
import FormAgamaSetting from "./Form";
import FullScreenLoader from "components/loader/FullScreenLoader";
import axiosInstance from "utils/axiosInstance";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export default function EditAgamaSetting() {

  const { agamaId } = useParams<{ agamaId: string }>();

  const {
    isLoading,
    isInitialLoading,
    data: agama,
    error,
  } = useQuery({
    queryKey: ["agama", agamaId],
    queryFn: async () => {
      const {data} = await axiosInstance.call({
        method: "GET",
        url: `/general/agama/show/${agamaId}`,
      });

      return data;
    },
    enabled: !!agamaId,
  });

  if (isLoading) {
    return <FullScreenLoader />;
  }

  return <FormAgamaSetting action="edit" initialData={agama?.data} />;
}
