import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Flex, Button, SimpleGrid } from "@chakra-ui/react";

import TextField from "components/fields/TextField";
import SelectField from "components/fields/SelectField";

import useOptions from "hooks/setting/general/useOptions";
import { useEditUsahawan } from "hooks/admin/usahawan/useEditUsahawan";

import { IJenisPerniagaanFormValues } from "entities/admin/usahawan";

import { formatDate } from "utils/helpers";

interface FormKodProjekProps {
  action: "edit" | "show";
  initialData?: IJenisPerniagaanFormValues;
}

const schema = yup
  .object({
    daftar_jenama: yup.string().notRequired(),
  })
  .required();

const FormKodProjek: React.FC<FormKodProjekProps> = ({
  action,
  initialData,
}) => {
  const history = useHistory();

  const { negeriOps, daerahOps, bandarOps, parlimenOps, dunOps, capaiSasaranOps } =
    useOptions();
  const { editUsahawan, isEditing } = useEditUsahawan();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IJenisPerniagaanFormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      ...initialData,
    },
  });

  const onSubmit = handleSubmit((data) => {
    editUsahawan({
      newUsahawan: {
        bpu_insentifs: JSON.stringify([data]),
      },
      id: initialData?.id,
    });
  });

  return (
    <Flex mt="10px" mb="1.5rem" flexDirection={"column"}>
      <form onSubmit={onSubmit}>
        <SimpleGrid
          columns={{ base: 2 }}
          gap={{ sm: "6px", md: "18px" }}
          mb={10}
        >
          <SelectField
            label={"Capai Sasaran"}
            options={capaiSasaranOps}
            errorMessage={errors.capai_sasaran?.message}
            isInvalid={!!errors.capai_sasaran}
            readOnly={action === "show"}
            {...register("capai_sasaran")}
          />

          <SelectField
            label={"Kategori Usahawan"}
            options={[]}
            errorMessage={errors.kategori_usahawan_id?.message}
            isInvalid={!!errors.kategori_usahawan_id}
            readOnly={action === "show"}
            {...register("kategori_usahawan_id")}
          />

          <TextField
            id="justifikasi_tidak_aktif"
            label="Justifikasi Tidak Aktif"
            errorMessage={errors.justifikasi_tidak_aktif?.message}
            isInvalid={!!errors.justifikasi_tidak_aktif}
            readOnly={action === "show"}
            {...register("justifikasi_tidak_aktif")}
          />

          <TextField
            id="daftar_jenama"
            label="Daftar Jenama"
            errorMessage={errors.daftar_jenama?.message}
            isInvalid={!!errors.daftar_jenama}
            readOnly={action === "show"}
            {...register("daftar_jenama")}
          />

          <TextField
            id="no_sijil_halal"
            label="No Sijil Halal"
            errorMessage={errors.no_sijil_halal?.message}
            isInvalid={!!errors.no_sijil_halal}
            readOnly={action === "show"}
            {...register("no_sijil_halal")}
          />

          <TextField
            id="medium_pemasaran"
            label="Medium Pemasaran"
            errorMessage={errors.medium_pemasaran?.message}
            isInvalid={!!errors.medium_pemasaran}
            readOnly={action === "show"}
            {...register("medium_pemasaran")}
          />

          <TextField
            id="usahawan_koperasi"
            label="Usahawan Koperasi"
            errorMessage={errors.usahawan_koperasi?.message}
            isInvalid={!!errors.usahawan_koperasi}
            readOnly={action === "show"}
            {...register("usahawan_koperasi")}
          />

          <TextField
            id="tinggal_serumah_pekebun"
            label="Tinggal Serumah Pekebun"
            errorMessage={errors.tinggal_serumah_pekebun?.message}
            isInvalid={!!errors.tinggal_serumah_pekebun}
            readOnly={action === "show"}
            {...register("tinggal_serumah_pekebun")}
          />

          <TextField
            id="tempoh_mula"
            label="Tempoh Mula"
            errorMessage={errors.tempoh_mula?.message}
            isInvalid={!!errors.tempoh_mula}
            readOnly={action === "show"}
            {...register("tempoh_mula")}
          />

          <TextField
            id="sumber_pembiayaan"
            label="Sumber Pembiayaan"
            errorMessage={errors.sumber_pembiayaan?.message}
            isInvalid={!!errors.sumber_pembiayaan}
            readOnly={action === "show"}
            {...register("sumber_pembiayaan")}
          />

          <TextField
            id="alamat_bpu_insentif_1"
            label="Alamat 1"
            errorMessage={errors.alamat_bpu_insentif_1?.message}
            isInvalid={!!errors.alamat_bpu_insentif_1}
            readOnly={action === "show"}
            {...register("alamat_bpu_insentif_1")}
          />

          <TextField
            id="alamat_bpu_insentif_2"
            label="Alamat 2"
            errorMessage={errors.alamat_bpu_insentif_2?.message}
            isInvalid={!!errors.alamat_bpu_insentif_2}
            readOnly={action === "show"}
            {...register("alamat_bpu_insentif_2")}
          />

          <SelectField
            label={"Negeri"}
            options={negeriOps}
            errorMessage={errors.negeri_id?.message}
            isInvalid={!!errors.negeri_id}
            readOnly={action === "show"}
            {...register("negeri_id")}
          />

          <SelectField
            label={"Daerah"}
            options={daerahOps}
            errorMessage={errors.daerah_id?.message}
            isInvalid={!!errors.daerah_id}
            readOnly={action === "show"}
            {...register("daerah_id")}
          />

          <SelectField
            label={"Bandar"}
            options={bandarOps}
            errorMessage={errors.bandar_id?.message}
            isInvalid={!!errors.bandar_id}
            readOnly={action === "show"}
            {...register("bandar_id")}
          />

          <SelectField
            label={"Parlimen"}
            options={parlimenOps}
            errorMessage={errors.parlimen_id?.message}
            isInvalid={!!errors.parlimen_id}
            readOnly={action === "show"}
            {...register("parlimen_id")}
          />

          <SelectField
            label={"Dun"}
            options={dunOps}
            errorMessage={errors.dun_id?.message}
            isInvalid={!!errors.dun_id}
            readOnly={action === "show"}
            {...register("dun_id")}
          />

          <TextField
            id="koordinat"
            label="Koordinat"
            errorMessage={errors.koordinat?.message}
            isInvalid={!!errors.koordinat}
            readOnly={action === "show"}
            {...register("koordinat")}
          />
        </SimpleGrid>

        <Flex gap="20px" justifyContent={"flex-end"} mb={"30px"}>
          <Button
            variant="outline"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            colorScheme="gray"
            type={"button"}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>

          <Button
            variant="brand"
            fontSize={"sm"}
            fontWeight={"500"}
            minW={"183px"}
            type="submit"
            isDisabled={isEditing}
          >
            Kemaskini Maklumat
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default FormKodProjek;
